import validator from 'validator'
import isEmpty from 'lodash/isEmpty'
import owaspPassword from 'owasp-password-strength-test'
import isURL from 'validator/lib/isURL'

import { ArtistSignupFormErrors } from './features/signup/ArtistSignup/ArtistSignupFields'
import { VenueSignupFormErrors } from './features/signup/VenueSignup/VenueSignupFields'
import { PromoterSignupFormErrors } from './features/signup/PromoterSignup/PromoterSignupFields'

import {
  UserSignupInput,
  ArtistSignupInput,
  VenueSignupInput,
  PromoterSignupInput,
} from './graphql/types.gen'
import { parseSpotifyLink } from './components/ProfileInfo/parseSpotifyLink'

// Password error ids:
// 0: "The password must be at least 10 characters long."
// 3: "The password must contain at least one lowercase letter."
// 4: "The password must contain at least one uppercase letter."
// 5: "The password must contain at least one number."
// 6: "The password must contain at least one special character."
owaspPassword.config({
  allowPassphrases: true,
  maxLength: 40,
  minLength: 8,
  minPhraseLength: 8,
  minOptionalTestsToPass: 3, // 4 if you want to include special char
})

// TODO: move these close to the components they're used in and delete the shared folder
export function validateLogin(data: Record<string, string>) {
  const { email, password } = data
  const errors: Record<string, string> = {}

  if (validator.isEmpty(email)) {
    errors.email = 'Required'
  }

  if (!validator.isEmail(email)) {
    errors.email = 'Invalid email'
  }

  if (validator.isEmpty(password)) {
    errors.password = 'Required'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export function validateUserSignupInput(data: UserSignupInput) {
  const { name, email, password, termsAccepted } = data
  const errors: Partial<UserSignupInput> = {}

  if (validator.isEmpty(name)) {
    errors.name = 'Required'
  }

  if (validator.isEmpty(email)) {
    errors.email = 'Required'
  }

  if (!validator.isEmail(email)) {
    errors.email = 'Invalid email'
  }

  if (validator.isEmpty(password)) {
    errors.password = 'Required'
  }

  const passwordStrength = owaspPassword.test(password)
  if (!passwordStrength.strong) {
    let passwordErrors = passwordStrength.errors.map((error: string) =>
      error.replace('The password must ', ''),
    )
    passwordErrors = passwordErrors.map((error: string) =>
      error.replace('.', ''),
    )

    // Join errors into one string
    errors.password = `The password should ${passwordErrors
      .slice(0, 3)
      .join(', ')}.`
  }

  if (!termsAccepted) {
    errors.termsAccepted = false
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export function validateEmail(email: string) {
  let error = ''

  if (validator.isEmpty(email)) {
    error = 'Required'
  }

  if (!validator.isEmail(email)) {
    error = 'Invalid email'
  }

  return {
    error,
    isValid: isEmpty(error),
  }
}

export function validateSlug(slug = '') {
  let error = ''
  const regEx = new RegExp('^[a-zA-Z0-9_.-]*$')

  if (slug.length < 1) {
    error += "Can't be empty. "
  } else if (!regEx.test(slug)) {
    error += 'Can only have letters, numbers, dashes, underscores and dots. '
  }

  return {
    error,
    isValid: error.length < 1,
  }
}

export function validatePassword(password: string) {
  const errors: Record<string, string> = {}

  if (validator.isEmpty(password)) {
    errors.password = 'Required'
  }

  const passStrength = owaspPassword.test(password)
  if (!passStrength.strong) {
    let passErrors = passStrength.errors.map((error: string) =>
      error.replace('The password must ', ''),
    )
    passErrors = passErrors.map((error: string) => error.replace('.', ''))

    // Join errors into one string
    errors.password = `The password should ${passErrors
      .slice(0, 3)
      .join(', ')}.`
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validateProfileForm(data: any) {
  const errors: Record<string, string> = {}

  const keys = Object.keys(data)

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = data[key]

    if (!isEmpty(value)) {
      if (key === 'slug') {
        const { error, isValid } = validateSlug(value)

        if (!isValid) {
          errors.slug = error
        }
      }
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export function validateArtistFields(data: ArtistSignupInput) {
  const errors: ArtistSignupFormErrors = {}
  const { name, genres, description, spotify } = data

  if (validator.isEmpty(name)) {
    errors.artistName = 'Required'
  }

  if (validator.isEmpty(description)) {
    errors.description = 'Required'
  }

  if (genres.length < 1) {
    errors.genres = 'Please pick your genres'
  }

  if (spotify) {
    let link = parseSpotifyLink(spotify)

    if (!link) {
      errors.spotify = 'Invalid link'
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export function validateVenueFields(data: VenueSignupInput) {
  const errors: VenueSignupFormErrors = {}
  const { name, address, description } = data

  if (validator.isEmpty(name)) {
    errors.venueName = 'Required'
  }

  if (validator.isEmpty(description)) {
    errors.description = 'Required'
  }

  if (validator.isEmpty(address)) {
    errors.address = 'Required'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export function validatePromoterFields(data: PromoterSignupInput) {
  const errors: PromoterSignupFormErrors = {}
  const { name, description } = data

  if (validator.isEmpty(name)) {
    errors.promoterName = 'Required'
  }

  if (validator.isEmpty(description)) {
    errors.description = 'Required'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

interface LinkError {
  name?: string
  url?: string
}
interface LinkValidation {
  error: LinkError
  isValid: boolean
}

export function validateLink(
  linkName: string,
  linkUrl: string,
): LinkValidation {
  const error: LinkError = {}

  if (linkName.length === 0 && linkUrl.length === 0) {
    return {
      error,
      isValid: true, // We filter empty links on submit
    }
  }

  if (isEmpty(linkName)) {
    error.name = 'The name is empty'
  }

  if (!isURL(linkUrl)) {
    error.url = 'The link is incorrect'
  }

  return {
    error,
    isValid: isEmpty(error),
  }
}
